.signUpLoginBox {
    background-image: url(images/bg-noise-texture.png);
    background-repeat: repeat;
    box-shadow: inset 0px 143px 108px -18px rgba(0, 0, 0, 0.39);
    display: flex;
    justify-content: center;

  
    .slContainer {
        flex: 0 1 50%;
        display: flex;
        margin-top: 80px;
        @include breakpoints(700){
            margin-top: 10px;
          }
    }
            .formBoxRight {
                flex: 0 1 100%;
            }
  
                .formContent {
                    width: 100%;
                    padding: 20px;
                    box-sizing: border-box;
                    @include breakpoints(700){
                        margin-bottom: 50px;
                      }

  
                    h2 {
                        margin: 0 0 40px 0;
                        padding: 0;
                        color: $white;
                        text-transform: uppercase;
                    }
  
                        .inputBox {
                            position: relative;
                            height: 80px;
  
                            input {
                                width: 100%;
                                padding: 10px 0;
                                font-size: 1.2em;
                                color: $red;
                                font-weight: bold;
                                margin-bottom: 30px;
                                border: none;
                                background: transparent;
                                border-bottom: 1px solid $white;
                                outline: none;
                            }
  
                                label {
                                    color: $white;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    padding: 10px 0;
                                    font-size: 1.2em;
                                    pointer-events: none;
                                    transition: 0.2s ease-in;
                                    @include breakpoints(700){
                                        font-size: 0.9em;
                                      }
                                }
                                    ul {
                                        margin-top: 60px;
                                        font-size: 1.2em;
                                        @include breakpoints(1251){
                                            font-size: 0.9em;
                                          }
                                    li {
                                        display: inline-block;
                                        padding-right: 15px;
                                        color: $white;
                                    }}
  
                                       input:focus~label,
                                       input:valid~label {
                                            top: -30px;
                                            left: 0;
                                            color: $white;
                                            font-size: 16px;
                                            font-style: italic;
                }
            }
         }
    }