.dropdown_section {
  position: absolute;
  top: 5px;
  right: -10px;
  width: 50px;
  z-index: 10000;

}

.dropdown_section > .dropdown {
    width: 30px;
}

.dropdown {
    &_header {
    align-items: center;
    background-color: $white;
    border-radius: 10px;
    display: inline-flex;
    height: 45px;
    width: 100%;
  }
  
  &_fleche {
    display: flex;
    justify-content: flex-end;
    margin-right: 7px;
    height: 20px;
    width: 100%;
  }

}