$white: #ffffff;
$light-grey-color: #EAE8E8;
$light-green-color: #1ecc78;
$green: #0cb765;
$red: #EB1D27;
$blue: #4f78a4;
$dark-red-color: #d31017;
$dark-grey-color: #363636;
$black: #333030;
$deep-black-color: #000000;



$breakpoints:(
    280: 280px,
    300: 300px,
    310: 310px,
    400: 400px,
    440: 440px,
    460: 460px,
    500: 500px,
    590: 590px,
    650: 650px,
    700: 700px,
    800: 800px,
    990: 990px,
    1000: 1000px,
    1100: 1100px,
    1251: 1251px,
    1490: 1490px,
);