footer {
    display: flex;
    justify-content: center;
    a,
    a:visited {
    color: $white;
    }
    @include breakpoints(1251){
      margin-top: 25px;
    }
  
  .footer-container {
    padding: 20px 0;
    padding: 20px 0;
    flex: 0 1 80%;
  }
  
  .footer-container p {
    color: $white;
    line-height: 19px;
    margin: 10px 0;
    padding: 0;
    font-size: 1.1em;
    font-weight: normal;
    @include breakpoints(300){
        font-size: 1em;
      }
  }
}