.logoutContainer {
    display: flex;
    justify-content: flex-end;

    .switch {
        position: absolute;
        top: 15px;
        right: 5px;
        width: 60px;
        height: 34px;
        cursor: pointer;
        @include breakpoints(460){
            top: 15px;
            right: -20px;
          }
          @include breakpoints(310){
            top: 10px;
            right: -25px;
          }

        img {
            background: $white;
            height: 50px;
            padding: 5px;
            border-radius: 50px;
            @include breakpoints(460){
                height: 30px;
              }
        }
    }

}