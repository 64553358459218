.error_page {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 50px;
    color: $red;
    min-height: calc(100vh - 336px);
    a,
    a:visited {
    color: $white;
    }

    
    &_quatreCent {
        font-size: 288px;
        line-height: 136px;
    }
    
        &_introuvable {
            font-size: 36px;
        }
        
            &_link {
                font-size: 18px;
                    &:hover {
                        letter-spacing: 1px;
                    }
            }

            .white {
                color: $white;
            }
}

@include breakpoints(590) {
    .error_page {
        &_quatreCent {
            font-size: 180px;
        }
    }
 }

 @include breakpoints(400) {
    .error_page {
        &_quatreCent {
            font-size: 150px;
        }
    }
 }

@include breakpoints(850) { 
    .error_page {
        margin-bottom: 51px;
        &_quatreCent {
            font-size: 96px;
        }
            &_introuvable {
                font-size: 18px;
            }
                &_link {
                    font-size: 14px;
                }
    }
}