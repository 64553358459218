header {
  width: 100%;
  height: 80px;
  border-bottom: 2px solid darken(white, 16%);
  a,
  a:visited {
  color: $white;
  }
  @include breakpoints(460){
    height: 60px;
  }

.banner-container {
  padding: 20px 0;
    
    .logo img { 
        display: flex;
        margin: auto;
        align-items: center;
        height: 40px;
        @include breakpoints(700){
          position: absolute;
          top: 20px;
          align-items: flex-start;
          margin: 0;
          margin-left: 10px;
        }
        @include breakpoints(460){
          height: 30px;
          top: 15px;
        }
        @include breakpoints(310){
          height: 25px;
          top: 12.5px;
        }
      }
      
      ul {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        justify-content: space-between;
        font-size: 1.2rem;
        top: 25px;
        left: 55px;
        
        .welcome {
          transform: translateY(2px);
  
          @include breakpoints(700)  {
            display: none;
          }
          
          h5 {
            font-family: "Lato", sans-serif;
          }
        }
        
       }
       .HomeContainer {
        display: flex;
        justify-content: flex-end;
    
        .switch {
            position: absolute;
            top: 15px;
            right: 120px;
            display: inline-block;
            width: 60px;
            height: 34px;
            cursor: pointer;
            @include breakpoints(460){
              top: 15px;
              right: 50px;
            }
            @include breakpoints(310){
              top: 10px;
              right: 45px;
            }
    
            img {
                background: $white;
                height: 50px;
                padding: 5px;
                border-radius: 50px;
                @include breakpoints(460){
                  height: 30px;
                }
            }
        }
    
    }

    .ProfilContainer {
      display: flex;
      justify-content: flex-end;
  
      .switch {
          position: absolute;
          top: 15px;
          right: 62px;
          display: inline-block;
          width: 60px;
          height: 34px;
          cursor: pointer;
          @include breakpoints(460){
            top: 15px;
            right: 15px;
          }
          @include breakpoints(310){
            top: 10px;
            right: 10px;
          }
  
          img {
              background: $white;
              height: 50px;
              padding: 5px;
              border-radius: 50px;
              @include breakpoints(460){
                height: 30px;
              }
          }
      }
  
  }
     }
    }
