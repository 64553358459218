.card_home {
    display: grid;
    grid-template-columns: 66px 1fr;
    background: $white;
    margin: 10px;
    padding: 16px;
    border-radius: 20px;
    margin: 8px 20px 0;
    position: relative;
    min-height: 100px;
  
    @include breakpoints(590) {
      grid-template-columns: 100% 1fr;
      margin: 8px 10px 0;
    }
    @include breakpoints(440) {
      padding-top: 26px;
    }
  
    .fa-spinner {
      position: absolute;
      top: 40%;
      left: 49%;
      font-size: 1.5rem;
      color: $red;
    }
  
    &_left {
      img {
        height: 52px;
        width: 52px;
        border-radius: 20px;
        box-shadow: 1px 1px 3px rgba(51, 51, 51, 0.192);
        object-fit: cover;
  
        @include breakpoints(590) {
          height: 40px;
          width: 40px;
          margin-left: 18px;
          border-radius: 14px;
        }
      }
      @include breakpoints(590) {
        position: absolute;
        transform: translate(17px, 18px);
      }
    }
  
    &_right {
        @include breakpoints(590) {
        margin-left: 18px;
      }
      &_header {
        display: flex;
        justify-content: space-between;
        margin: 2px 0 4px;
        min-height: 35px;
  
        @include breakpoints(590) {
          margin: 12px 0 14px;
        }
  
        @include breakpoints(440)  {
          flex-direction: column;
        }
  
       &--name {
          display: flex;
          font-size: 1.1rem;
          a,
          a:visited {
            color: $black;
          }
  
          @include breakpoints(590) {
            margin-left: 52px;
          }
          @include breakpoints(440)   {
            transform: translateY(-18px);
            font-size: 0.9rem;
          }
          .margin {
            margin-left: 5px;
          }
        }
    
  
        span {
          font-style: italic;
          font-size: 1.2rem;
          font-weight: 500;
          margin-right: 30px;

          @include breakpoints(700)  {
            font-size: 1rem;
          }
          @include breakpoints(440)  {
            transform: translate(52px, -20px);
            font-size: 0.95rem;
          }
          @include breakpoints(280)  {
            transform: translate(52px, -20px);
            font-size: 0.8rem;
          }
        }
      }
      p {
        margin: 3px 0 9px;
        text-align: center;
        font-size: 1.1rem;
        overflow: auto;
  
        @include breakpoints(440)  {
          margin-top: -18px;
          font-size: 1rem;
        }
      }
      .update_post {
        textarea {
          font-size: 1.3rem;
          border: 1px solid $red;
          padding: 10px;
          border-radius: 20px 0px 20px 20px;
          width: 100% !important;
          height: 100px;
        }
        .button_container {
          text-align: right;

          .btn {
            width: 150px;
            background: lighten($black, 20%);
            margin-left: 5px;
            margin-top: 5px;
            height: 30px;
            width: 25%;
            border-radius: 20px 6px 20px 20px;
            transform: translateY(-3px);
            font-size: 1.1rem;
            font-weight: 600;
            color: $white;
            cursor: pointer;
            @include breakpoints(400)  {
              font-size: 1.1rem;
              width: 50%;
            }
            &:hover {
              background: $black;
              width: 26%;
              letter-spacing: 1px;
            }
          }
        }
        .button_container_picture {
          text-align: center;

          h3 {
            text-align: center;
            margin: 10px;
          }
            .btn {
              width: 150px;
              background: lighten($black, 20%);
              margin-left: 5px;
              margin-top: 5px;
              height: 30px;
              width: 25%;
              border-radius: 20px 6px 20px 20px;
              transform: translateY(-3px);
              font-size: 1.1rem;
              font-weight: 600;
              color: $white;
              @include breakpoints(400)  {
                font-size: 1.1rem;
                width: 50%;
              }
              &:hover {
                background: $black;
                width: 26%;
                letter-spacing: 1px;
              }
          }
        }
      }
      & .card_pic {
        width: 35%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 6px;
        margin-top: 12px;
        box-shadow: 0 0 3px rgba(51, 51, 51, 0.342);
        @include breakpoints(1100)  {
          width: 50%;
        }
        @include breakpoints(700)  {
          width: 70%;
        }
        @include breakpoints(500)  {
          width: 85%;
        }
        @include breakpoints(400)  {
          width: 100%;
        }
      }

      
      .button-container {
        position: relative;
        justify-content: flex-end;
        display: flex;

  
        div {
          padding: 5px 9px;
          margin-left: 5px;
          margin-top: 5px;
          margin-right: 3px;
          background: $white;
          border-radius: 50px;
          box-shadow: 1px 1px 1px 1px $black;
          transition: 0.1s;
          cursor: pointer;
  
          &:hover {
            transform: scale(1.2);
          }
            input {
            width: 55px;
            height: 55px;
            position: absolute;
            transform: translate(0px, -5px);
            opacity: 0;
            cursor: pointer;
          
            &::-webkit-file-upload-button {
              cursor: pointer;
            }
          }
  
          img {
            height: 28px;
            width: 28px;
            transform: translateY(3px);
            @include breakpoints(700)  {
              height: 25px;
              width: 25px;
            }
            @include breakpoints(400)  {
              height: 20px;
              width: 20px;
            }
          }
        }
      }

      .card_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: space-between;
        padding: 12px 0 4px;
  
        img {
          height: 40px;
          width: 40px;
          cursor: pointer;
          transition: 0.2s;
          @include breakpoints(1000)  {
            height: 30px;
            width: 30px;
          }
          @include breakpoints(590)  {
            height: 22px;
            width: 22px;
          }
  
          &:hover {
            transform: scale(1.2);
          }
        }
        &_comment,
        &_like {
          display: flex;
          align-items: center;
  
          span {
            font-size: 1.1rem;
            position: absolute;
            transform: translate(40px, -2px);
            @include breakpoints(1000)  {
              font-size: 1.08rem;
              transform: translate(30px, -2px);
            }
            @include breakpoints(590)  {
              font-size: 1.02rem;
              transform: translate(24px, -2px);
            }
          }
        }
      }
      .comment.client {
        background: lighten($red, 40%);
        border: none;
        border-radius: 20px 20px 0 20px;
      }
      .comment {
        display: grid;
        grid-template-columns: 50px 1fr;
        background: lighten($black, 70%);
        padding: 15px;
        margin: 5px 0;
        border-radius: 20px 20px 20px 0;
  
        img {
          height: 40px;
          width: 40px;
          border-radius: 14px;
          box-shadow: 1px 1px 3px rgba(51, 51, 51, 0.192);
          object-fit: cover;
  
          @include breakpoints(440) {
            height: 37px;
            width: 37px;
          }
        }
        &_header {
          display: flex;
          justify-content: space-between;
          a,
          a:visited {
            color: $black;
          }
  
          @include breakpoints(440) {
            flex-direction: column;
          }
  
          .pseudo {
            display: flex;
            font-size: 1.1rem;

            @include breakpoints(440)   {
              font-size: 0.9rem;
            }
            .margin {
              margin-left: 5px;
            }
  
            span {
              transform: translate(6px, 4px);
              cursor: pointer;
  
              img {
                box-shadow: none;
                height: 15px;
                width: 15px;
              }
            }
          }
  
          span {
            font-size: 0.9rem;
            font-style: italic;
          }
        }
        p {
          margin-top: 8px;
        }
        .comment_edit {
          color: $red;
          img {
            float: right;
            margin-left: 10px;
            cursor: pointer;
            transition: 0.15s;
            box-shadow: none;
            height: 25px;
            width: 25px;

  
            &:hover {
              transform: scale(1.3);
            }
          }
  
          &--form{
            margin-top: 14px;
            label {
              background: $white;
              color: $black;
              padding: 6px 12px 5px;
              border-radius: 20px 20px 20px 6px;
              transition: 0.2s;
              display: inline-block;
            }
            textarea {
              margin: 4px 0;
              background: $white;
              font-size: 1.15rem;
              padding: 16px;
              width: 100% !important;
              height: 100px;
              border-radius: 6px 20px 6px 20px;
            }
  
             .btn-delete {
              display: flex;
              align-items: center;
              float: left;
              padding: 2px;
              margin-left: 5px;
              margin-top: 10px;
              background: $white;
              border-radius: 20px;
              transition: 0.1s;
              cursor: pointer;
      
              &:hover {
                transform: scale(1.1);
                box-shadow: 1px 1px 1px 1px $black;
              }
              img {
                height: 25px;
                width: 25px;
                transform: translate(-3px);
                @include breakpoints(400)  {
                  height: 20px;
                  width: 20px;
                }
              }
              }
              .btn {
                display: flex;
                align-items: center;
                float: right;
                
                input[type="submit"] {
                  padding: 12px 16px;
                  width: 120px;
                  background: lighten($black, 20%);
                  border-radius: 20px 6px 20px 20px;
                  transition: 0.2s;
                  float: right;
                  color: $white;
                  cursor: pointer;
          
                  &:hover {
                    background: $black;
                    letter-spacing: 2px;
                    width: 130px;
                  }
                }
            }
          }
        }

      }

      .comment_form {
        margin-top: 6px;
  
        input[type="text"] {
          width: 100%;
          background: $white;
          border: 1px solid $black;
          border-radius: 20px 20px 6px 20px;
          padding: 20px;
          font-size: 1.4rem;
  
          &:focus {
            box-shadow: 0 0 2px rgba(51, 51, 51, 0.5);
          }
        }
        input[type="submit"] {
          margin-top: 4px;
          padding: 12px 16px;
          width: 120px;
          background: lighten($black, 20%);
          border-radius: 20px 6px 20px 20px;
          transition: 0.2s;
          float: right;
          color: $white;
          cursor: pointer;
  
          &:hover {
            background: $black;
            letter-spacing: 2px;
            width: 130px;
          }
        }
        button {
          float: right;
          background: lighten($black, 20%);
          transition: 0.2s;
          color: $white;
  
          &:hover {
            background: $black;
          }
        }
      }
      .like-container {
        span {
          font-size: 1.02rem;
          position: absolute;
          transform: translate(8px, -1px);
        }
      }
      .fa-share-alt {
        transform: translate(0, 2px);
      }
    }
  }
  
  